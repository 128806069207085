import {
    contentDiv,
    firstGrid,
    headingText, eminityImageText,
    paraText,
} from "../../styles/main.module.css";
import * as React from "react";
import image9 from "../../images/Canal-Walk.png";
import image10 from "../../images/Boulevard-Walk.png";
import image11 from "../../images/Parkland.png";
import image12 from "../../images/Restaurants.png";
import image13 from "../../images/Running-and-Cycling.png";
import image14 from "../../images/Tracks.png";
import {StaticImage} from "gatsby-plugin-image";


const Eminity = () => {
    return (<>
        <div className={'pb-9 sm:pb-16'}>
            <StaticImage
                placeholder="dominantColor"
                width={1500}
                height={600}
                src={'../../images/mirdif/aboutUsHeader.jpg'}
                alt={'eminity'}/>
        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>FACILITIES</h2>
                <p className={paraText}></p>
            </div>

            <div className={'sm:my-20 my-10'}>
                <div
                    className={`grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 gap-0 sm:gap-y-20 sm:gap-x-12 ${firstGrid}`}>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}>
                            <img src={image9}
                                 alt=""/>
                        </div>
                        <p className={eminityImageText}><span>Swimming pool</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image10}/></div>
                        <p className={eminityImageText}><span>Children’s pool</span></p>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image11}/></div>
                        <p className={eminityImageText}><span>Outdoor Jacuzzi </span></p>

                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image12}/></div>

                        <p className={eminityImageText}><span>Medical Centres</span></p>

                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image13}/></div>
                        <p className={eminityImageText}><span>Nurseries</span></p>


                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Hotel</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Retail outlets</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Restaurants and coffee shops</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Multi-purpose hall/Community Centre</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Covered parking</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Gymnasium </span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Recreational facilities</span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>Kids play area </span></p>

                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image14}/></div>

                        <p className={eminityImageText}><span>24-hour security and maintenance</span></p>

                    </div>


                </div>


            </div>

        </div>
    </>)
}

export default Eminity
