import {
    buttonCss,
    contentDiv,
    firstGrid,
    gridText,
    headingText, iconLine,
    minClass,
    minTextClass,
    paraText
} from "../../styles/main.module.css";
import image1 from "../../images/AL-KHAIL-ROAD.png";
import image2 from "../../images/MEYDAN-RACECOURSE.png";
import image3 from "../../images/MEYDAN-ONE-MALL-MEYDAN-TOWER.png";
import image4 from "../../images/FESTIVAL-CITY-IKEA-1.png";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";


const Connectivity = () => {
    return (<>
        <div className={'pb-9 sm:pb-16'}>
            <StaticImage
                placeholder="dominantColor"
                width={1500}
                height={600}
                src={'../../images/mirdif/janayen.jpg'}
                alt={'frontView'}/>
        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>DESTINATION WITHIN A DESTINATION</h2>
                <p className={paraText}>
                    In the heart of a thriving family community with all the comforts of the city, enjoy the advantages
                    of an exceptional lifestyle in an inimitable location, one of Dubai’s most sought-after residential
                    hotspots. A network of sophisticated, secure and comfortable gated communities, Mirdif is close to
                    Dubai International Airport. The area is easily connected to Dubai’s leisure and business districts,
                    metro stations and major traffic routes, with good access to all Emirates.
                </p>
            </div>
            <div className={'sm:my-20 my-10'}>

                <div
                    className={`grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 gap-0 sm:gap-y-20 sm:gap-x-12 ${firstGrid}`}>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image1}/></div>
                        <p className={gridText}>SCHOOL</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>
                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>
                    </div>
                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image2}/></div>
                        <p className={gridText}>RASHYIDAH METRO STATION</p>
                        <p className={minClass}>10</p>
                        <p className={minTextClass}>MIN</p>
                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>
                    </div>

                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image3}/></div>
                        <p className={gridText}>DUBAI INTERNATIONAL AIRPORT</p>
                        <p className={minClass}>12</p>
                        <p className={minTextClass}>MIN</p>
                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>
                    </div>


                    <div className={'flex flex-col items-center'}>
                        <div className={'sm:mb-6 mb-5'}><img alt={'c'}
                                                             src={image4}/></div>
                        <p className={gridText}>PARKS</p>
                        <p className={minClass}>15</p>
                        <p className={minTextClass}>MIN</p>

                        <div className={'sm:hidden pt-0 py-4 w-2/3'}>
                            <span className={`${iconLine} `}/>
                        </div>

                    </div>
                </div>

                <div className={'pt-14 sm:pt-16'}>
                    <p><a className={buttonCss} href="#">I Am
                        Interested</a></p>

                </div>
            </div>


        </div>
    </>)
}

export default Connectivity
