import {
    headerSection,
    mainSection,
    heroText,
} from '../mirdifHillsComp/heroArea.module.css'
import {
    buttonCss,
    contentDiv,
    headingText,
    paraText,
} from '../../styles/main.module.css'
import FormLayout from './form'
import * as React from 'react'
import {StaticImage} from 'gatsby-plugin-image'

const HeroArea = ({countryISO, short, symbol}) => {
    return (<>

        <div className={mainSection}>
            <section className={`${headerSection} text-center pt-4 bg-left md:bg-center `}>
                <div className={'text-white text-center relative my-7'}>
                    <div>
                        <div className={`mb-5 m-auto  leading-6  text-center  tracking-widest ${heroText}`}>
                            READY TO MOVE APARTMENTS IN <br/> ONLY FIRST FREEHOLD GATED <br/> COMMUNITY PROJECT IN
                            MIRDIF
                        </div>
                    </div>
                    <div>
                        <p className={'m-auto mb-2 text-xs sm:text-sm md:max-w-xl w-2/3'}>
                            Pay 20% and move in today.
                        </p>
                        <p className={'m-auto mb-5 text-xs sm:text-sm md:max-w-xl w-2/3'}>
                            Pay 80% in 5 years post hand over
                        </p>
                    </div>
                    <div>
                        <p className={'m-auto text-xs sm:text-sm tracking-wider md:max-w-xl w-3/4'}>
                            AVAILABLE FROM:
                        </p>
                    </div>
                    <div>
                        <h1 className={'m-auto text-3xl font-bold md:max-w-xl w-3/4'}>
                            {/*<span className={'text-lg'}>( $149,733 / €129,117)</span>*/}
                            {symbol} {short}
                        </h1>
                    </div>

                </div>
            </section>
            <FormLayout countryISO={countryISO}/>
            <div className={'py-12'}>
                <div className={`${contentDiv} container`}>
                    <h2 className={headingText}>RIGHT PLACE, RIGHT TIME</h2>
                    <p className={paraText}>Dubai is becoming a destination of distinction; one that enriches the lives
                        of residents and visitors every day. With a sustainable, diversified economy, Dubai has proven
                        itself to be a great place to live in, learn from and do business.

                        The Emirate’s continuous and exhilarating development is characterized by an inspired contrast
                        of heritage and modernity,
                        authenticity and inspiration. Beautifully blending old with new, Dubai is a beacon for
                        enlightened development that understands the value of its richness in culture. Mirdif Hills is
                        the bold new beating heart of inspired living in this dynamic city

                    </p>
                </div>

            </div>
        </div>

        <div className={'py-10 text-center'}>
            <p><a className={buttonCss} href="#">I Am
                Interested</a></p>

        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>FAMILY-LIFESTYLE </h2>
                <p className={paraText}>
                    With stunning architecture, inspired finishes and world-class amenities, Mirdif Hills provides a
                    variety of unique residences to complement any family lifestyle, set against the backdrop of the
                    spectacular Dubai skyline.
                </p>
            </div>
        </div>

        <div className={'container pb-9 sm:pb-16'}>

        </div>
    </>)
}

export default HeroArea
