import {
    buttonCss,
    contentDiv,
    headingText,
    paraText,
} from "../../styles/main.module.css";
import {averageCss} from "./apartments.module.css";
import * as React from "react";
import bottom1 from "../../images/bottomImage1.jpg";
import bottom2 from "../../images/bottomImage2.jpg";
import bottom3 from "../../images/bottomImage3.jpg";
import bottom4 from "../../images/bottomImage4.jpg";
import {StaticImage} from "gatsby-plugin-image";
import {footerFloatingBox} from "./form.module.css";

const Apartments = () => {
    return (<>

        <div className={'pb-9 sm:pb-16'}>
            <StaticImage
                placeholder="dominantColor"
                width={1500}
                height={600}
                src={'../../images/mirdif/apartmentBackground.jpg'}
                alt={'phase2'}/>
        </div>
        <div className={`${contentDiv} container`}>
            <div>
                <h2 className={headingText}>MIRDIF APARTMENTS</h2>
                <p className={paraText}>At Mirdif Hills, you can choose from studios, 1,
                    2, 3 bedroom apartments with beautiful interiors overlooking the Canal.</p>
            </div>

            <div className={averageCss}>
                <p><strong>Studios </strong>: Average Size – 35 sq.m.</p>
                <p><strong>1 BR</strong> : Average Size – 55 sq.m.</p>
                <p><strong>2 BR</strong> : Average Size – 85 sq.m.</p>
                <p><strong>3 BR</strong> : Average Size – 1100 sq.m.</p>
            </div>

            <div className={'mt-8'}>
                <div className="grid grid-cols-1 md:mb-4 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className={`text-center border bg-gray-50 border-opacity-20 ${footerFloatingBox}`}>
                        <p className={'sm:text-base text-sm text-left p-2 text-primary-gray'}>Studio Apartment</p>
                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/mirdif/studioApartment.jpg'}
                                alt={'bottom1'}/>
                        </div>

                    </div>
                    <div className={`text-center border bg-gray-50 border-opacity-20 ${footerFloatingBox}`}>
                        <p className={'sm:text-base text-sm text-left p-2 text-primary-gray'}>One Bedroom Apartment</p>

                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/mirdif/one-bedroom-apartment.jpg'}
                                alt={'bottom2'}/>
                        </div>
                    </div>

                    <div className={`text-center border bg-gray-50 border-opacity-20 ${footerFloatingBox}`}>
                        <p className={'sm:text-base text-sm text-left p-2 text-primary-gray'}>Two Bedroom Apartment</p>

                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/mirdif/two-bedroom-apartment-1.jpg'}
                                alt={'bottom3'}/>
                        </div>
                    </div>
                    <div className={`text-center border bg-gray-50 border-opacity-20 ${footerFloatingBox}`}>
                        <p className={'sm:text-base text-sm text-left p-2 text-primary-gray'}>Two Bedroom Apartment</p>

                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/mirdif/two-bedroom-apartment-2.jpg'}
                                alt={'bottom4'}/>
                        </div>
                    </div>
                    <div className={`text-center border bg-gray-50 border-opacity-20 ${footerFloatingBox}`}>
                        <p className={'sm:text-base text-sm text-left p-2 text-primary-gray'}>Three Bedroom
                            Apartment</p>

                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/mirdif/three-bedroom-apartment-1.jpg'}
                                alt={'bottom4'}/>
                        </div>
                    </div>
                    <div className={`text-center border bg-gray-50 border-opacity-20 ${footerFloatingBox}`}>
                        <p className={'sm:text-base text-sm text-left p-2 text-primary-gray'}>Three Bedroom
                            Apartment</p>

                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/mirdif/three-bedroom-apartment-2.jpg'}
                                alt={'bottom4'}/>
                        </div>
                    </div>
                    <div className={`text-center border bg-gray-50 border-opacity-20 ${footerFloatingBox}`}>
                        <p className={'sm:text-base text-sm text-left p-2 text-primary-gray'}>Three Bedroom Duplex</p>

                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/mirdif/three-bedroom-duplex.jpg'}
                                alt={'bottom4'}/>
                        </div>
                    </div>
                    <div className={`text-center border bg-gray-50 border-opacity-20 ${footerFloatingBox}`}>
                        <p className={'sm:text-base text-sm text-left p-2 text-primary-gray'}>Four Bedroom Duplex</p>

                        <div>
                            <StaticImage
                                className={'m-auto'}
                                placeholder="dominantColor"
                                src={'../../images/mirdif/four-bedroom-duplex.jpg'}
                                alt={'bottom4'}/>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div className={`${contentDiv} container`}>
            <div className={'pb-10'}>
                <p><a className={buttonCss} href="#">I Am
                    Interested</a></p>

            </div>
            <div className={'mb-8'}>
                <h2 className={headingText}>LOCATION
                </h2>

                <p className={paraText}>
                    Mirdif is among the most preferred residential areas located right in the heart of Dubai and
                    accessible from all parts of the Emirates. With sophisticated gated communities, Mirdif is well
                    connected to all popular leisure and business district highways. Mirdif offers the best of all
                    worlds, slightly away from the throng of the city but still equipped with lifestyle amenities such
                    as major hypermarket chains, retail outlets, cafes &
                    restaurants, educational institutions, healthcare centres, and parks.
                </p>


            </div>
            <div>
                <a href={'https://g.page/aziziriviera?share'}>
                    <StaticImage
                        className="m-auto"
                        placeholder="dominantColor"
                        src={'../../images/mirdif/mirdifLocation.jpeg'}
                        alt={'mapImage'}/>
                </a>
            </div>

        </div>

    </>)
}

export default Apartments
