import * as React from 'react'
import {} from '../styles/main.module.css'
import NavHeader from '../components/NavHeader'
import HeroArea from '../components/mirdifHillsComp/heroArea'
import Connectivity from '../components/mirdifHillsComp/connectivities'
import Eminity from '../components/mirdifHillsComp/eminity'
import Plan from '../components/mirdifHillsComp/plan'
import Apartments from '../components/mirdifHillsComp/apartments'
import Footer from '../components/footer'
import {StaticImage} from 'gatsby-plugin-image'
import ModalPage from '../../src/components/modalPage'
import SEO from '../components/App/SEO'
import {siteUrl} from '../components/_utils'
import {useScrollTracker} from 'react-scroll-tracker'
import ReactGA from 'react-ga'
import {useEffect, useState} from 'react'
import {getCountry} from '../action'
import midrifBackground from '../images/mirdif/backgroundMidrif.jpg'
const shortNumber = require('short-number')

let dollarAmount = 149733
const MirdifHills = ({data}) => {

    const [countryISO, setCountryISO] = useState('ae')
    const [short, setShort] = useState(shortNumber(dollarAmount))
    const [symbol, setSymbol] = useState('$')

    useEffect(() => {
        getCountry({amount: dollarAmount}).then((data) => {
            console.log(data)

            if (data.short) {
                setShort(data.short)
            }
            if (data.symbol) {
                setSymbol(data.symbol)
            }
            if (data.iso) {
                setCountryISO(data.iso.toLowerCase())
            }
        })
    }, [])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (typeof window !== 'undefined') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useScrollTracker([10, 15, 25, 50, 75, 100], ({scrollY}) => {
            ReactGA.event({
                category: 'scroll',
                action: scrollY,
            })
        })
    }

    const logoComp = (
        <StaticImage
            className="w-14 md:w-28 lg:w-42 align-middle"
            placeholder="tracedSVG"
            src={'../images/mirdif/mirdif-hills-logo.png'}
            alt={'Mirdif Hills'}
        />
    )
    const titleParams = {
        title: `Mirdif Hills Overview | ${siteUrl}`,
        ogTitle: `Mirdif Hills Overview | ${siteUrl}`,

        description: 'Janayen. Nasayem. Al Multaqa. Mirdif Hills is a highend real estate project with residential, commercial, and retail development across 4 million square feet. It includes a hotel and a hospital. It is a green, state-ofthe-art, inspirational family living space.',
        ogDescription: 'Janayen. Nasayem. Al Multaqa. Mirdif Hills is a highend real estate project with residential, commercial, and retail development across 4 million square feet. It includes a hotel and a hospital. It is a green, state-ofthe-art, inspirational family living space.',
        keywords: 'At Mirdif Hills, you can choose from studios, 1, 2, 3 bedroom apartments with beautiful interiors overlooking the Canal.',

        ogImage: midrifBackground,
        secureImageUrl: midrifBackground,
        ogImageAlt: 'Mirdif Hills',

        pageLink: `${siteUrl}mirdifHills/`,
        orUrl: `${siteUrl}mirdifHills/`,
        canonicalRef: `${siteUrl}mirdifHills/`,

    }
    return (<>
        <SEO data={titleParams}/>
        <NavHeader logoComp={logoComp}/>
        <HeroArea countryISO={countryISO} short={short} symbol={symbol}/>
        <Connectivity/>
        <Eminity/>
        <Plan/>
        <Apartments/>
        <Footer countryISO={countryISO}/>
        <ModalPage/>
    </>)
}

export default MirdifHills
