import {
    contentDiv,
    headingText
} from "../../styles/main.module.css";
import {phasePara,phaseCss} from "./park.module.css";
import * as React from "react";
import phase1 from "../../images/phase1.jpg";
import {StaticImage} from "gatsby-plugin-image";


const Park = () => {
    return (<div className={phaseCss}>

            <div className={'pb-9 sm:pb-16'}>
                <StaticImage
                    placeholder="dominantColor"
                    width={1500}
                    height={600}
                    src={'../../images/mirdif/facility.png'}
                    alt={'photo'}/>
            </div>

            <div className={`${contentDiv} container`}>
                <div>
                    <h2 className={headingText}>PAYMENT PLAN
                    </h2>
                </div>

                <div className={'my-10'}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
                        <div className="text-left">
                            <h1>PHASE 1
                            </h1>

                            <p className={phasePara}>Starting at AED 550K</p>
                            <p className={phasePara}>Pay 20% & Move In Today</p>
                            <p className={phasePara}>Pay 80% In 5 Years Post Handover</p>

                        </div>
                        <div className="text-center">
                            <StaticImage
                                className="m-auto"
                                height={225}
                                placeholder="dominantColor"
                                src={'../../images/mirdif/pool.png'}
                                alt={'phase1'}/>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Park
