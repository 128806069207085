import {

    floatingBox,
    input,
} from './form.module.css'
import * as React from 'react'
// import ReactFlag from "../reactFlagsComp";
import {useForm} from 'react-hook-form'
import {addForm} from '../../action'
import {siteUrl} from '../_utils'
import {telinputContainer, loaderText} from '../fiveJBR/form.module.css'
import ReactFlag from '../reactFlagsComp-back'
import {ImSpinner} from 'react-icons/im'

const FormLayout = (props) => {
    const {register, handleSubmit, formState: {errors}} = useForm()
    const [mobile, setMobile] = React.useState()
    const [loading, setLoading] = React.useState(false)
    let [phoneCode, setCode] = React.useState('');

    const onSubmit = async (data) => {

        if (!mobile) {
            alert("Mobile Number is required");
            return;
        }
        if (!phoneCode) {
            alert("Phone Code  is required");
            return;
        }

        setLoading(true)
            let x = await addForm({...data, mobile: mobile,phoneCode})
            setLoading(false)
            if (!x.error) {
                window.location.href = `${siteUrl}thankuPage`
            } else {
                console.log(x)
            }


    }

    return (<div className={`sm:px-5 md:max-w-2xl w-11/12 m-auto bg-white ${floatingBox}`}>
            <div className={'p-6 '}>

                <div className={'text-l text-center text-sm text-primary-gray'}>
                    SEND US A MESSAGE
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={'my-4'}><input
                        className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                        type="text" placeholder="Name" id={'name'} required={true} {...register('name')}/></div>
                    <div className={'flex my-4'}>
                        <div className={`border border-primary-gray border-opacity-20 ${telinputContainer}`}>
                            <ReactFlag countryISO={props.countryISO} footerFlag={false} flagValue={(value) => {
                                if(value && value.mobile){
                                    setMobile(value.mobile);
                                    setCode(value.code);
                                }else{
                                    if(value.mobile){
                                        setMobile('')
                                    }
                                    if(value.code){
                                        setCode('')
                                    }
                                }
                            }}/>
                        </div>

                    </div>
                    <div className={'my-4'}><input
                        className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                        type="email" placeholder="Email" id={'email'} {...register('email')} required={true}/></div>
                    <div className={'my-4'}>
                        <select {...register('apartment')}
                                className={'w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm '}
                                id={'select'} name="apartment">
                            <option value="studio">Studio</option>
                            <option value="1Apartment">1 bedroom apartment</option>
                            <option value="2Apartment">2 bedrooms apartment</option>
                            <option value="3Apartment">3 bedrooms apartment</option>
                            <option value="3Duplex">3 bedrooms duplexes</option>
                            <option value="4Duplex">4 bedrooms duplexes</option>
                            <option value="others">Others</option>
                        </select>
                    </div>

                    <div className={'my-4'}>
                        <textarea {...register('message')}
                                  className={`w-full border border-primary-gray border-opacity-20 py-2 px-4 text-primary-gray text-sm `}
                                  id="message" name="message" rows="4" cols="50"/>
                    </div>
                    <div className={'my-4'}>
                        <button disabled={loading}
                                style={{flex: 1, alignContent: 'center'}}
                                className={'w-full text-sm hover:bg-primary-gray bg-primary-light py-2 px-4 text-white'}
                                type="submit">

              <span className={loaderText}>
                {loading && <ImSpinner className={'icon-spin '}/>}
              </span>

                            <span>Send</span>
                        </button>
                    </div>

                </form>

            </div>
        </div>
    )
}

export default FormLayout
